<template>
  <div class="view flex flex-d ai-c">
    <headers :styleSize="'min'" :times="headTime" languageType="EN"></headers>
    <img src="@/assets/speaial_activity_img/imageEn/time_title.png" class="time-title" alt="">
    <TitleAndTime :time="dataTime" languageType="EN">
      <img src="@/assets/speaial_activity_img/imageEn/ninetitle.png" class="title" alt="">
    </TitleAndTime>
    <div class="main flex-1">
      <div class="mainData flex flex-d ai-c" v-for="(item,index) in nineData" :key="index">
        <div class="rankind-item">
          <div class="ranking-title" @mouseleave='rankingTitleAllIndex=null'>
            <p class="title" @mouseenter="hoverShowRankingTitle" :data-index="item.category+'-all'">{{item.category}}</p>
            <span class="hover-title-all" v-show="rankingTitleAllIndex==item.category+'-all'">{{item.category}}</span>
          </div>
          <div class="ranking-content flex jc-c" v-for="(itemChild,indexChild) in item.topCategoryBrand" :key="indexChild">
            <div class="ranking-order">
              <img v-if="indexChild<3" :src="imgIconRanking[indexChild]" alt="" class="hotIcon">
              <span class="level" v-else>{{ (indexChild + 1).toString().padStart(2, '0') }}</span>
            </div>
            <span class="ranking-name" @mouseenter="hoverShowSubName" @mouseleave='subNameShowAllIndex=null' :data-index="index+'-'+indexChild">{{itemChild.brandEn}}</span>
            <span class="init-name" v-show="subNameShowAllIndex==index+'-'+indexChild">{{itemChild.brandEn}}</span>
          </div>
        </div>

      </div>
    </div>
    <Annotation languageType="EN" />
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import Annotation from '@/components/Big/Annotation'
import TitleAndTime from '@/components/Big/TitleAndTime'
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  components: {
    Headers,
    TitleAndTime,
    Annotation,
  },
  data() {
    return {
      rankingTitleAllIndex: null,
      subNameShowAllIndex: null,
      nineData: [],
      headTime: '',
      dataTime: '',
      imgIconRanking: [
        require('@/assets/speaial_activity_img/diyiming.png'),
        require('@/assets/speaial_activity_img/dierming.png'),
        require('@/assets/speaial_activity_img/disanming.png'),
      ],
    }
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        this.init(newValue)
      },
    },
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(value) {
      // console.log('楼层9 en', value)
      this.nineData = []
      // let list = []
      // const firstKey = Object.keys(value)[0]
      // this.dataTime = this.$dateFormat(value[firstKey][0].time)
      this.dataTime = this.$dateFormat(value[0].topCategoryBrand[0].time)
      // this.dataTime = this.$dateFormat('10日20:00-11日08:00')
      // for (var key in value) {
      //   list.push({
      //     title: value[key][0].categoryEn,
      //     data: value[key].slice(0, 10),
      //   })
      // }
      // this.nineData = list.splice(0, 8)
      this.nineData = value.map((res) => {
        return {
          category: res.topCategoryBrand[0].categoryEn,
          topCategoryBrand: res.topCategoryBrand.slice(0, 10),
        }
      })
    },
    hoverShowRankingTitle(e) {
      if (e.target.scrollWidth > e.target.offsetWidth) {
        this.rankingTitleAllIndex = e.target.getAttribute('data-index')
      }
    },
    hoverShowSubName(e) {
      if (e.target.scrollWidth > e.target.offsetWidth) {
        this.subNameShowAllIndex = e.target.getAttribute('data-index')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/en.scss';
</style>
